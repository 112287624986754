import { ChangeEvent, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Channel } from '../../types/slot-template';
import DownloadCSVButton from './download-csv-btn';

interface Props {
  show: boolean;
  onHide: () => void;
  onFileSelected: (file?: File) => void;
  onChannelSelected: (channelId?: string) => void;
  onUpload: () => Promise<void>;
  loading?: boolean;
  channels: Channel[];
}

export function CSVUploadModal({
  show,
  channels,
  onHide,
  onFileSelected,
  onChannelSelected,
  onUpload,
  loading
}: Props) {
  const [channelId, setChannelId] = useState('');

  function handleFileSelected(event: ChangeEvent<HTMLInputElement>) {
    onFileSelected(event.target.files?.[0]);
  }

  function handleChannelSelected(event: ChangeEvent<HTMLSelectElement>) {
    setChannelId(event.target.value);
    onChannelSelected(event.target.value);
  }

  function handleOnHide() {
    setChannelId('');
    onHide();
  }

  return (
    <Modal show={show} onHide={handleOnHide} as="div">
      <Modal.Header>
        <Modal.Title>Select a CSV File to upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="file">
            <Form.Label>Select a CSV File</Form.Label>
            <Form.Control
              type="file"
              accept=".csv"
              placeholder="Select CSV File"
              onChange={handleFileSelected}
            />
          </Form.Group>
          <Form.Group
            className="d-flex align-items-center justify-content-between d-none mb-3"
            controlId="channel">
            <div className="me-3 w-30 label-container">
              <Form.Label>Channel</Form.Label>
            </div>
            <Form.Select
              className="input"
              value={channelId}
              onChange={handleChannelSelected}
              required
              data-testid="channel"
              name="channel">
              <option value="">Select Channel</option>
              {channels?.map(({ id, channel_name }: Channel) => (
                <option key={id} value={id}>
                  {channel_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <DownloadCSVButton />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleOnHide} disabled={loading}>
          Cancel
        </Button>
        <Button className="ms-3" variant="primary" onClick={onUpload} disabled={loading}>
          {loading ? 'Uploading' : 'Upload'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
