export interface FormData {
  cutOffTime: string;
  nameEn: string;
  nameTh: string;
  address1: string;
  address2: string;
  address3: string;
  address1En: string;
  address2En: string;
  address3En: string;
  city: string;
  cityEn: string;
  state: string;
  stateEn: string;
  latitude: string;
  longitude: string;
  isStorePickup: any;
}

interface FormField {
  label: string;
  name: keyof FormData;
  type: string;
  placeholder: string;
  section: string | null;
}

export const formFields: FormField[] = [
  {
    label: 'Cut-off Time',
    name: 'cutOffTime',
    type: 'date',
    placeholder: 'Enter store cuf-off time',
    section: null
  },
  {
    label: 'Enable Store Pickup',
    name: 'isStorePickup',
    type: 'checkbox',
    placeholder: '',
    section: null
  },
  {
    label: 'Name (English)',
    name: 'nameEn',
    type: 'text',
    placeholder: 'Enter name (English)',
    section: 'en'
  },
  {
    label: 'Name (Thai)',
    name: 'nameTh',
    type: 'text',
    placeholder: 'Enter name (Thai)',
    section: 'th'
  },
  {
    label: 'Address Line 1',
    name: 'address1',
    type: 'text',
    placeholder: 'Enter address line 1',
    section: 'th'
  },
  {
    label: 'Address Line 1',
    name: 'address1En',
    type: 'text',
    placeholder: 'Enter address line 1 in english',
    section: 'en'
  },
  {
    label: 'Address Line 2',
    name: 'address2',
    type: 'text',
    placeholder: 'Enter address line 2',
    section: 'th'
  },
  {
    label: 'Address Line 2',
    name: 'address2En',
    type: 'text',
    placeholder: 'Enter address line 2 in english',
    section: 'en'
  },
  {
    label: 'Address Line 3',
    name: 'address3',
    type: 'text',
    placeholder: 'Enter address line 3',
    section: 'th'
  },
  {
    label: 'Address Line 3',
    name: 'address3En',
    type: 'text',
    placeholder: 'Enter address line 3 in english',
    section: 'en'
  },
  {
    label: 'City',
    name: 'city',
    type: 'text',
    placeholder: 'Enter city',
    section: 'th'
  },
  {
    label: 'City',
    name: 'cityEn',
    type: 'text',
    placeholder: 'Enter city in english',
    section: 'en'
  },
  {
    label: 'State',
    name: 'state',
    type: 'text',
    placeholder: 'Enter state',
    section: 'th'
  },
  {
    label: 'State',
    name: 'stateEn',
    type: 'text',
    placeholder: 'Enter state in english',
    section: 'en'
  },
  {
    label: 'Latitude',
    name: 'latitude',
    type: 'text',
    placeholder: 'Enter latitude',
    section: null
  },
  {
    label: 'Longitude',
    name: 'longitude',
    type: 'text',
    placeholder: 'Enter longitude',
    section: null
  }
];
