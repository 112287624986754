import { gql } from '@apollo/client';

// Define query
export const GET_ALL_STORES = gql`
  query ($accessToken: String!) {
    stores(accessToken: $accessToken) {
      id
      storeCode
    }
  }
`;
