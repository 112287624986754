import { gql } from '@apollo/client';

export const UPDATE_STORE_CONFIG = gql`
  mutation (
    $accessToken: String!
    $storeCode: String!
    $updateStoreConfigInput: UpdateStoreConfigInput!
  ) {
    updateStoreConfig(
      accessToken: $accessToken
      storeCode: $storeCode
      updateStoreConfigInput: $updateStoreConfigInput
    ) {
      storeCode
    }
  }
`;
