import { createContext, useContext, useState } from 'react';

interface StoreCodeContext {
  timeslotStoreCode: string;
  timeslotTemplateStoreCode: string;
  timeslotChannel: string;
  timeslotTemplateChannel: string;
  setTimeslotStoreCode: (storeCode: string) => void;
  setTimeslotTemplateStoreCode: (storeCode: string) => void;
  setTimeslotChannel: (channel: string) => void;
  setTimeslotTemplateChannel: (channel: string) => void;
}

const StoreCodeContext = createContext<StoreCodeContext>({
  timeslotStoreCode: '',
  timeslotTemplateStoreCode: '',
  timeslotChannel: '',
  timeslotTemplateChannel: '',
  setTimeslotStoreCode: () => {
    // void
  },
  setTimeslotTemplateStoreCode: () => {
    // void
  },
  setTimeslotChannel: () => {
    // void
  },
  setTimeslotTemplateChannel: () => {
    // void
  }
});

export const useStoreCodeContext = () => useContext(StoreCodeContext);

export const StoreCodeContextProvider = ({ children }: React.PropsWithChildren) => {
  const [timeslotStoreCode, setTimeslotStoreCode] = useState('');
  const [timeslotTemplateStoreCode, setTimeslotTemplateStoreCode] = useState('');
  const [timeslotChannel, setTimeslotChannel] = useState('');
  const [timeslotTemplateChannel, setTimeslotTemplateChannel] = useState('');

  return (
    <StoreCodeContext.Provider
      value={{
        timeslotStoreCode,
        timeslotTemplateStoreCode,
        timeslotChannel,
        timeslotTemplateChannel,
        setTimeslotStoreCode,
        setTimeslotTemplateStoreCode,
        setTimeslotChannel,
        setTimeslotTemplateChannel
      }}
    >
      {children}
    </StoreCodeContext.Provider>
  );
};
