import { gql } from '@apollo/client';

export const CREATE_SLOT_TEMPLATE = gql`
  mutation ($accessToken: String!, $CreateMasterSlotTypeInput: CreateMasterSlotTypeInput!) {
    masterSlotTypeCreate(
      accessToken: $accessToken
      CreateMasterSlotTypeInput: $CreateMasterSlotTypeInput
    )
  }
`;

export const UPDATE_SLOT_TEMPLATE_BY_ID = gql`
  mutation ($accessToken: String!, $id: ID!, $updateSlotInput: UpdateSlotTypeInput!) {
    slotTypeUpdate(accessToken: $accessToken, id: $id, UpdateSlotTypeInput: $updateSlotInput) {
      id
    }
  }
`;

export const UPDATE_MASTER_SLOT_TEMPLATE_BY_ID = gql`
  mutation ($accessToken: String!, $id: ID!, $updateMasterSlotInput: UpdateMasterSlotInput!) {
    masterSlotUpdateById(
      accessToken: $accessToken
      id: $id
      UpdateMasterSlotInput: $updateMasterSlotInput
    ) {
      id
    }
  }
`;

export const CHANGE_MASTER_SLOT_TEMPLATE_BY_ID = gql`
  mutation ($accessToken: String!, $id: ID!, $changeMasterSlotInput: ChangeMasterSlotInput!) {
    masterSlotChangeById(
      accessToken: $accessToken
      id: $id
      ChangeMasterSlotInput: $changeMasterSlotInput
    ) {
      id
    }
  }
`;

export const UPDATE_SLOT_TEMPLATES_BY_BATCH = gql`
  mutation BatchUpdateSlotTypes($accessToken: String!, $data: [BatchSlotTypeInput!]!) {
    batchUpdateSlotTypes(accessToken: $accessToken, data: $data)
  }
`;

export const UPDATE_MASTER_SLOT_TEMPLATE_BY_BATCH = gql`
  mutation BatchUpdateMasterSlotTemplates($accessToken: String!, $data: [UpdateMasterSlotInput!]!) {
    batchUpdateMasterSlotTemplates(data: $data, accessToken: $accessToken)
  }
`;
