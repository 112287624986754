import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Form, Spinner, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/loading-button';
import { CutOffTime, CutOffTimeComponentProps, CutOffTimeForm } from '../../types/cut-off-time';
import './index.scss';
import CustomTimePickerFormControl from '../../components/time-picker';
import { GET_SLOT_CONFIG } from '../../graphql/queries/slot-config';
import {
  DB_TIME_FORMAT,
  FETCH_POLICY,
  FORBIDDEN_RESOURCE_ERROR,
  THAILAND_TIMEZONE,
  TIME_FORMAT
} from '../../constants';
import { withCookies } from 'react-cookie';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_SLOT_CONFIG } from '../../graphql/mutations/slot-config';
import moment from 'moment';
import { UPDATE_CUTOFF_TIME_MSG, WARNING_FORM_NOT_UPDATE } from '../../constants/messages';
import { errorEvent } from '../../utils/log-event';
import { Link } from 'react-router-dom';

export function CutOffTimeComponent({ cookies, setUser }: CutOffTimeComponentProps) {
  const accessToken = cookies.get('token');
  const [getSlotConfigs, { loading: isFetching, error }] = useLazyQuery<{
    slotConfigs: CutOffTime[];
  }>(GET_SLOT_CONFIG, {
    fetchPolicy: FETCH_POLICY,
    variables: {
      accessToken
    }
  });
  const [isSaving, setSavingFlag] = useState(false);
  const [updateSlotConfigById, { error: updateError }] = useMutation(UPDATE_SLOT_CONFIG);
  const [storesCutOffTime, setStoreCutOffTime] = useState<CutOffTimeForm[]>([]);

  useEffect(() => {
    const onInitComponent = async () => {
      try {
        const { data } = await getSlotConfigs();
        if (data?.slotConfigs) {
          const configs = data?.slotConfigs;
          const store04Index = configs.findIndex((config: any) => config.storeCode === '04');
          const store04Exist = store04Index > -1;

          const actualConfigs = store04Exist
            ? configs.map((config: any) => {
                if (config.storeCode == '804') {
                  config = { ...configs[store04Index] };
                  config.storeCode = '804';
                }
                return config;
              })
            : configs;

          const updatedConfigs = actualConfigs.map((cutOffTime: CutOffTime) => {
            const cutoffTimeFormatted = moment(
              `${cutOffTime.cutOffHours}:${cutOffTime.cutOffMinutes}:${cutOffTime.cutOffSeconds}+07`,
              DB_TIME_FORMAT
            ).format(TIME_FORMAT);

            return {
              ...cutOffTime,
              cutOffTime: cutoffTimeFormatted,
              oldCutOffTime: cutoffTimeFormatted
            };
          });
          console.log(updatedConfigs);
          setStoreCutOffTime(updatedConfigs);
        }
      } catch (error) {
        errorEvent(error);
      }
    };
    onInitComponent();
  }, [getSlotConfigs, setStoreCutOffTime]);

  useEffect(() => {
    const errorMsg = error?.message || updateError?.message;
    if (errorMsg === FORBIDDEN_RESOURCE_ERROR) {
      setUser();
    } else if (errorMsg) {
      toast.error(errorMsg);
    }
  }, [error, updateError, setUser]);

  const setCutOffTimeFormValueByIndex = (index: number, value: string) => {
    const tempStoresCutOffTime = [...storesCutOffTime];
    tempStoresCutOffTime[index].cutOffTime = value as string;
    tempStoresCutOffTime[index].isUpdate =
      tempStoresCutOffTime[index].cutOffTime !== tempStoresCutOffTime[index].oldCutOffTime;
    setStoreCutOffTime([...tempStoresCutOffTime]);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    try {
      const promiseArr = storesCutOffTime
        .filter((cutOffTime) => cutOffTime.isUpdate)
        .map(({ storeCode, cutOffTime, cutOffTimeZone, eveningSlotAfter }) => {
          const from = moment(cutOffTime, TIME_FORMAT).format(DB_TIME_FORMAT);
          const [cutOffHours, cutOffMinutes] = from.split(':');
          return updateSlotConfigById({
            variables: {
              accessToken,
              updateSlotConfigInput: {
                storeCode,
                cutOffHours: Number(cutOffHours),
                cutOffMinutes: Number(cutOffMinutes),
                cutOffSeconds: 0,
                cutOffTimeZone: cutOffTimeZone || THAILAND_TIMEZONE,
                eveningSlotAfter
              }
            }
          });
        });
      if (!promiseArr.length) {
        toast.warning(WARNING_FORM_NOT_UPDATE);
        return;
      }
      setSavingFlag(true);
      const res = await Promise.all(promiseArr);
      if (res.length) {
        const tempStoresCutOffTime = [...storesCutOffTime];
        setStoreCutOffTime([
          ...tempStoresCutOffTime.map(({ isUpdate, ...rest }) => ({
            ...rest,
            isUpdate: !isUpdate,
            oldCutOffTime: rest.cutOffTime
          }))
        ]);
        toast.success(UPDATE_CUTOFF_TIME_MSG);
      }
      setSavingFlag(false);
    } catch (error) {
      setSavingFlag(false);
      toast.error(error as string);
    }
  };

  if (isFetching) {
    return (
      <>
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="secondary" />
        <Spinner animation="grow" variant="success" />
      </>
    );
  }
  return (
    <div className="cut-off-time-container">
      <Form onSubmit={handleSubmit} className=" w-100" data-testid="cutoff-time-form">
        <h3 className="ps-3">Update store config</h3>
        <Table className="time-slot-template-table" striped bordered hover responsive>
          <thead>
            <tr>
              <th>Store Id</th>
              <th>Cut-off Time</th>
              <th>Store name (EN)</th>
              <th>Store name (TH)</th>
              <th>Address</th>
              <th>Lat/Long</th>
              <th>Is Store Pickup</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {storesCutOffTime.map((cutOffTime, index) => (
              <tr key={index}>
                <td>{cutOffTime.storeCode}</td>
                <td>
                  {cutOffTime.cutOffTime?.toUpperCase()}
                  <Form.Group
                    className="d-none align-items-center justify-content-between"
                    controlId="id">
                    <div className="round-input input value w-100">
                      <Form.Control
                        className="round-input login-input "
                        value={cutOffTime.cutOffTime}
                        onChange={(e) => {
                          setCutOffTimeFormValueByIndex(index, e.target.value);
                        }}
                        as={CustomTimePickerFormControl}
                        name={`cutOffTime_${index}`}
                        data-testid={`cutOffTime_${index}`}
                      />
                    </div>
                  </Form.Group>
                </td>
                <td>
                  <div>
                    <div>{cutOffTime.nameEn}</div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>{cutOffTime.nameTh}</div>
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      {cutOffTime.address1}
                      {cutOffTime.address2 && `, ${cutOffTime.address2}`}
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    {cutOffTime.latitude != '-' && cutOffTime.latitude != null && (
                      <div>
                        {cutOffTime.latitude}, {cutOffTime.longitude}
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  <div>{cutOffTime.isStorePickup}</div>
                </td>
                <td>
                  <Link to={`/store-config/edit/${cutOffTime.storeCode}`}>
                    <Button variant="primary" size="sm">
                      Edit
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="mt-4 d-none align-items-center justify-content-around">
          <div className="d-flex align-items-center justify-content-evenly w-100">
            <LoadingButton
              label="Update"
              loadingLabel="Updating..."
              isDisabled={isSaving}
              isLoading={isSaving}
              testId={'submit'}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default withCookies(CutOffTimeComponent);
