import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
  concat
} from '@apollo/client';
import { API_COMMON_HEADER, GRAPHQL_URL_V2 } from './constants';

const url = GRAPHQL_URL_V2;

const httpLink = new HttpLink({ uri: url });
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: API_COMMON_HEADER
  });
  return forward(operation);
});
const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ApolloProvider client={client}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
