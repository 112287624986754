import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Cookies, withCookies } from 'react-cookie';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import TimeSlot from './screens/time-slot';
import TimeSlotTemplate from './screens/time-slot-template';
import Login from './screens/login';
import { User } from './types/user';
import Header from './components/header';
import UpdateTimeSlot from './screens/update-time-slot';
import CreateUpdateSlotTemplate from './screens/create-update-time-slot-template';
import CutOffTimeComponent from './screens/store-config';
import {
  getUserFromLocalStorage,
  removeUserToLocalStorage,
  setUserToLocalStorage
} from './utils/local-storage';
import { StoreCodeContextProvider } from './contexts/storeCode.context';
import EditStoreConfig from './screens/store-config/edit';

interface AppInterface {
  cookies: Cookies;
}
function App({ cookies }: AppInterface) {
  const [user, setUser] = useState<User | null>(getUserFromLocalStorage());
  const accessToken = cookies.get('token');
  const setLoggedInUser = (user?: User | null) => {
    if (user) {
      setUserToLocalStorage(user);
    } else {
      cookies.remove('token');
      removeUserToLocalStorage();
    }
    setUser(user || null);
  };

  return (
    <StoreCodeContextProvider>
      <ToastContainer />
      <Router>
        {accessToken ? (
          <main className="private-main">
            <Header setUser={setLoggedInUser} user={user} />
            <div className="private-container">
              <Routes>
                <Route
                  path="/time-slot"
                  element={<TimeSlot setUser={setLoggedInUser} user={user} />}
                />
                <Route
                  path="/time-slot-template"
                  element={<TimeSlotTemplate setUser={setLoggedInUser} user={user} />}
                />
                <Route
                  path="/store-config"
                  element={<CutOffTimeComponent setUser={setLoggedInUser} user={user} />}
                />
                <Route
                  path="/store-config/edit/:storeCode"
                  element={<EditStoreConfig setUser={setLoggedInUser} user={user} />}
                />
                <Route
                  path="/update-time-slot/:slotId"
                  element={<UpdateTimeSlot setUser={setLoggedInUser} user={user} />}
                />
                <Route
                  path="/time-slot-template-create"
                  element={<CreateUpdateSlotTemplate setUser={setLoggedInUser} user={user} />}
                />
                <Route
                  path="/time-slot-template/:slotTemplateId"
                  element={<CreateUpdateSlotTemplate setUser={setLoggedInUser} user={user} />}
                />
                <Route path="*" element={<Navigate replace to="/time-slot" />} />
              </Routes>
            </div>
          </main>
        ) : (
          <main className="public-main">
            <div className="public-container">
              <Routes>
                <Route path="/login" element={<Login setUser={setLoggedInUser} />} />
                <Route path="*" element={<Navigate replace to="/login" />} />
              </Routes>
            </div>
          </main>
        )}
      </Router>
    </StoreCodeContextProvider>
  );
}

export default withCookies(App);
