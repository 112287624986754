export const formatStoreCode = (storeCode: number, minLength = 2): string => {
  if (storeCode === null || storeCode === undefined) {
    return '';
  }
  const codeString = storeCode.toString();
  return codeString.length >= minLength ? codeString : codeString.padStart(minLength, '0');
};

export const formatSlotTime = (time: string): string => {
  const [hour, minutes] = time.split(':');
  return hour.length == 1 ? `0${hour}:${minutes}:00` : `${hour}:${minutes}:00`;
};

export function formatHours(input: string | number): string {
  const timeFormatRegex = /^\d{2}:\d{2}:\d{2}$/;

  if (typeof input === 'string' && timeFormatRegex.test(input)) {
    return input;
  }

  const hours: number = typeof input === 'string' ? parseInt(input) : input;
  if (isNaN(hours) || hours < 0 || hours > 23) {
    throw new Error('Invalid hour value');
  }
  const formattedHours: string = hours.toString().padStart(2, '0');

  return `${formattedHours}:00:00`;
}
