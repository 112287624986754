import React, { Button, Spinner } from 'react-bootstrap';
import { LoadingButtonProps } from '../../types/common';
import './index.scss';

export function LoadingButton({
  isLoading,
  isDisabled,
  label,
  loadingLabel,
  testId,
  onClick,
  className
}: LoadingButtonProps) {
  return (
    <Button
      type="submit"
      variant="primary"
      className={`d-flex align-items-center justify-content-center ${className}`}
      disabled={isDisabled}
      data-testid={testId}
    >
      {isLoading && (
        <Spinner
          className="me-1"
          size="sm"
          animation="border"
          variant="light"
          onClick={(e) => onClick && onClick(e)}
        />
      )}
      {isLoading && (loadingLabel || label)}
      {!isLoading && label}
    </Button>
  );
}

export default LoadingButton;
