import { useMutation, useLazyQuery, ApolloError } from '@apollo/client';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Row, Spinner, Table, Form } from 'react-bootstrap';
import { withCookies } from 'react-cookie';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import { FORBIDDEN_RESOURCE_ERROR, FETCH_POLICY } from '../../constants';
import { TimeSlotTemplateColumns } from '../../constants/table-schema';
import {
  GET_SLOT_TEMPLATES,
  GET_MASTER_SLOT_TEMPLATES,
  GET_FILTERED_MASTER_SLOT_TEMPLATES
} from '../../graphql/queries/slot-template';
import {
  CHANGE_MASTER_SLOT_TEMPLATE_BY_ID,
  UPDATE_MASTER_SLOT_TEMPLATE_BY_BATCH,
  UPDATE_SLOT_TEMPLATE_BY_ID
} from '../../graphql/mutations/slot-template';
import {
  Channel,
  SlotTemplate,
  SlotTemplateFields,
  TimeSlotTemplateComponentProps
} from '../../types/slot-template';
import './index.scss';
import { errorEvent } from '../../utils/log-event';
import { TIME_SLOT_TEMPLATE_STATUS_UPDATE_MSG } from '../../constants/messages';
import { CSVUploadModal } from '../../components/csv-upload-modal';
import { GET_ALL_CHANNELS } from '../../graphql/queries/channel';
import { GET_ALL_STORES } from '../../graphql/queries/store';
import { Store } from '../../types/store';
import { formatSlotTime, formatStoreCode } from '../../utils/format-data';

export function TimeSlotTemplate({ cookies, setUser }: TimeSlotTemplateComponentProps) {
  const accessToken = cookies.get('token');
  const navigate = useNavigate();
  const [updateSlotTemplateById, { error: updateError }] = useMutation(UPDATE_SLOT_TEMPLATE_BY_ID);
  const [changeMasterSlotyTemplateById, { error: changeError }] = useMutation(
    CHANGE_MASTER_SLOT_TEMPLATE_BY_ID
  );
  const [updateSlotTemplateByBatch, { loading }] = useMutation(
    UPDATE_MASTER_SLOT_TEMPLATE_BY_BATCH
  );
  const [getSlotTemplates, { loading: isFetching, error }] = useLazyQuery<{
    slotTypeAll: SlotTemplate[];
  }>(GET_SLOT_TEMPLATES, {
    fetchPolicy: FETCH_POLICY,
    variables: {
      accessToken
    }
  });
  const [getAllMasterSlotTemplates, { loading: isFilterFetching, error: isFilterError }] =
    useLazyQuery<{
      fetchAllMasterSlots: SlotTemplate[];
    }>(GET_MASTER_SLOT_TEMPLATES);
  const [
    getSlotTemplatesByStoreCodeAndType,
    { loading: isMasterFilterFetching, error: isMasterFilterError }
  ] = useLazyQuery<{
    masterSlotsByStoreCodeAndType: SlotTemplate[];
  }>(GET_FILTERED_MASTER_SLOT_TEMPLATES);
  const [stores, setStores] = useState<Store[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [getAllStores, { loading: isStoreFetching, error: storeError }] =
    useLazyQuery(GET_ALL_STORES);
  const [getAllChannels, { loading: isChannelsFetching, error: channelsError }] =
    useLazyQuery(GET_ALL_CHANNELS);

  const [allMasterSlotTemplates, setAllMasterSlotTemplates] = useState<SlotTemplate[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<SlotTemplate[]>([]);
  const [file, setFile] = useState<File>();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [invalidFilter, setInvalidFilter] = useState(false);
  const [channelId, setChannelId] = useState('');
  const [selectedChannelId, setSelectedChannelId] = useState('');
  const [selectedStoreCode, setSelectedStoreCode] = useState('');
  const [selectedSlotType, setSelectedSlotType] = useState('');
  const [selectedActiveSlot, setSelectedActiveSlot] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const handleFileSelected = (file?: File) => {
    if (file) {
      setFile(file);
    }
  };

  const handleChannelSelected = (channelId?: string) => {
    setChannelId(channelId || '');
  };

  const handleOnHide = () => {
    setChannelId('');
    setFile(undefined);
    setShowUploadModal(false);
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast.error('No file selected');
      return;
    }

    const parsedCsv: any = await new Promise((resolve) => {
      Papa.parse<any, File>(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          resolve(result.data);
        }
      });
    });

    // validate data
    const keys = Object.keys(parsedCsv[0] || {});
    const validKeys = [
      'storeCode',
      'from',
      'to',
      'maxCapacity',
      'isActive',
      'isSameDay',
      'minPrepTime',
      'sameDayCapacity',
      'isSpecialSlot',
      'percentageForSM1',
      'updateSlots',
      'isExpressSlot'
    ];

    let hasInvalidKeys = false;

    keys.forEach((key) => {
      if (!validKeys.includes(key)) {
        hasInvalidKeys = true;
      }
    });

    if (hasInvalidKeys || !keys.length) {
      toast.error('Invalid csv structure, please contact dev team for valid csv structure');
      return;
    }

    const csv = parsedCsv
      .map((row: any) => {
        if (row.storeCode !== null && row.storeCode !== undefined) {
          return {
            ...row,
            from: formatSlotTime(row.from),
            to: formatSlotTime(row.to),
            storeCode: formatStoreCode(row.storeCode),
            maxCapacity: parseFloat(row.maxCapacity),
            isActive: row.isActive.toUpperCase() === 'TRUE',
            isSameDay: row.isSameDay.toUpperCase() === 'TRUE',
            sameDayCapacity: parseInt(row.sameDayCapacity, 10),
            isSpecialSlot: row.isSpecialSlot.toUpperCase() === 'TRUE',
            percentageForSM1: parseFloat(row.percentageForSM1),
            updateSlots: row.updateSlots.toUpperCase() === 'TRUE',
            isExpressSlot: row.isExpressSlot.toUpperCase() === 'TRUE'
          };
        }
        return null; // Skip rows with null or undefined storeCode
      })
      .filter((row: any) => row !== null);
    // upload data to backend
    try {
      const { data } = await updateSlotTemplateByBatch({
        variables: { accessToken, data: csv }
      });
      if (data?.batchUpdateMasterSlotTemplates) {
        toast.success('Updated master slot templates, refresh to load updated templates');
        setShowUploadModal(false);
      }
    } catch (error: any) {
      const message = (error as ApolloError).message;
      setChannelId('');
      setFile(undefined);
      if (message === FORBIDDEN_RESOURCE_ERROR) {
        toast.error('Token expired, please reauthenticate');
        setUser();
        return;
      }

      toast.error(message);
    }
  };

  useEffect(() => {
    const onInitComponent = async () => {
      try {
        const storeCodePassed = searchParams.get('storeCode') || location?.state?.storeCode || '';
        const channelIdPassed = searchParams.get('channelId') || location?.state?.channelId || '';

        setSearchParams({ storeCode: storeCodePassed, channelId: channelIdPassed });
        setSelectedStoreCode(storeCodePassed);
        setSelectedChannelId(channelIdPassed);

        const { data } = await getAllMasterSlotTemplates({
          variables: {
            accessToken
          }
        });

        const slotTemplates = data?.fetchAllMasterSlots ?? [];

        setAllMasterSlotTemplates(slotTemplates);
        setFilteredTemplates(slotTemplates);

        const { data: allStores } = await getAllStores({
          variables: {
            accessToken
          }
        });
        const sortedStoreCodes = allStores?.stores
          .map((store) => ({ ...store, storeCode: parseInt(store.storeCode) }))
          .sort((a, b) => a.storeCode - b.storeCode);
        setStores(sortedStoreCodes);
        const { data: allChannels } = await getAllChannels({
          variables: {
            accessToken
          }
        });
        setChannels(allChannels?.channels);
      } catch (error) {
        errorEvent(error);
      }
    };
    onInitComponent();
  }, [
    getSlotTemplates,
    getAllMasterSlotTemplates,
    setAllMasterSlotTemplates,
    setFilteredTemplates,
    getAllStores,
    getAllChannels,
    setSelectedStoreCode,
    setSelectedChannelId
  ]);

  useEffect(() => {
    const errorMsg = error?.message || updateError?.message;
    if (errorMsg === FORBIDDEN_RESOURCE_ERROR) {
      setUser();
    } else if (errorMsg) {
      toast.error(errorMsg);
    }
  }, [error, updateError, storeError, channelsError, isFilterError, isMasterFilterError, setUser]);

  const filterSlots = async () => {
    setInvalidFilter(false);
    const { data } = await getSlotTemplatesByStoreCodeAndType({
      variables: {
        storeCode: selectedStoreCode ? selectedStoreCode : undefined,
        active: selectedActiveSlot ? selectedActiveSlot === 'active' : undefined,
        isSpecialSlot: selectedSlotType ? selectedSlotType === 'special' : undefined,
        isExpressSlot: selectedSlotType ? selectedSlotType === 'express' : undefined,
        accessToken
      }
    });

    setSearchParams({
      storeCode: selectedStoreCode,
      active: selectedActiveSlot,
      type: selectedSlotType
    });
    if (data && data?.masterSlotsByStoreCodeAndType?.length > 0) {
      setFilteredTemplates(data.masterSlotsByStoreCodeAndType);
    } else {
      setInvalidFilter(true);
      getErrorMessage();
    }
  };

  const updateSlotsOnFrontend = (index: number, state: boolean) => {
    const slots = [...filteredTemplates];
    slots[index] = { ...slots[index], isActive: state, updating: false };
    setFilteredTemplates(slots);
  };

  const setIsUpdating = (index: number) => {
    const slots = [...filteredTemplates];
    slots[index] = { ...slots[index], updating: true };
    setFilteredTemplates(slots);
  };

  const getChannelName = () => {
    if (selectedChannelId) {
      const channel = channels?.find(({ id }: Channel) => id === selectedChannelId);
      return channel?.channel_name || '';
    }
    return null;
  };

  const getErrorMessage = () => {
    const selectedChannelName = getChannelName();
    const error =
      selectedChannelName && selectedStoreCode
        ? `No slot template found for storeCode  ${selectedStoreCode} and channel ${selectedChannelName}`
        : selectedStoreCode
        ? `No slot template found for storeCode: ${selectedStoreCode}`
        : selectedChannelName
        ? `No slot template found for channel: ${selectedChannelName}`
        : 'No slot template found for the filter';
    setErrorMessage(error);
  };

  const clearFilters = async () => {
    setSelectedChannelId('');
    setSelectedStoreCode('');
    setSelectedSlotType('');
    setSelectedActiveSlot('');
    searchParams.delete('storeCode');
    searchParams.delete('channelId');
    const { data } = await getAllMasterSlotTemplates({
      variables: { accessToken }
    });
    if (data && data?.fetchAllMasterSlots?.length > 0) {
      setFilteredTemplates(data.fetchAllMasterSlots);
    }
    setInvalidFilter(false);
  };

  const onAction = async (
    e: MouseEvent<HTMLElement>,
    actionType: string,
    slotTemplate?: SlotTemplate,
    index = 0
  ): Promise<void> => {
    console.log('Slot template', slotTemplate, e);
    e.preventDefault();
    switch (actionType) {
      case 'markAsActive':
        if (slotTemplate) {
          setIsUpdating(index);
          try {
            const { data } = await changeMasterSlotyTemplateById({
              variables: {
                accessToken,
                id: slotTemplate?.id,
                changeMasterSlotInput: {
                  isActive: !slotTemplate?.isActive
                }
              }
            });
            if (data) {
              toast.success(
                TIME_SLOT_TEMPLATE_STATUS_UPDATE_MSG.replace(
                  '{{STATUS}}',
                  slotTemplate.isActive ? 'active' : 'in-active'
                )
              );
              updateSlotsOnFrontend(index, !slotTemplate?.isActive);
            }
          } catch (error) {
            errorEvent(error);
          }
        }
        break;
      case 'update':
        navigate(`/time-slot-template/${slotTemplate?.id}`, {
          state: {
            storeCode: selectedStoreCode,
            channelId: selectedChannelId
          }
        });
        break;
      case 'create':
        navigate('/time-slot-template-create');
        break;

      default:
        break;
    }
  };

  if (
    isFetching ||
    isStoreFetching ||
    isChannelsFetching ||
    isFilterFetching ||
    isMasterFilterFetching
  ) {
    return (
      <>
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="secondary" />
        <Spinner animation="grow" variant="success" />
      </>
    );
  }
  return (
    <>
      <Container className="mt-5">
        <Row className="mb-3">
          <Col lg={9}>
            <h3>Time-slot Template (This will affect for next 8 days)</h3>
          </Col>
          <Col lg={3} className="d-flex align-item-center justify-content-end">
            <Button className="me-5" onClick={() => setShowUploadModal(true)}>
              Upload CSV
            </Button>
            <Button
              data-testid="button_create"
              variant="primary"
              onClick={(e) => onAction(e, 'create')}>
              Create New Template
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Row>
              <Form.Group className="search-input my-5 d-none" controlId="channel">
                <Form.Select
                  className="input"
                  value={selectedChannelId}
                  onChange={(e: any) => setSelectedChannelId(e.target.value)}
                  data-testid="channel"
                  name="channel">
                  <option value="">Select Channel</option>
                  {channels?.map(({ id, channel_name }: Channel) => (
                    <option key={id} value={id}>
                      {channel_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="search-input my-5" controlId="storeCode">
                <Form.Select
                  className="input"
                  value={selectedStoreCode}
                  onChange={(e: any) => setSelectedStoreCode(e.target.value)}
                  data-testid="storeCode"
                  name="storeCode">
                  <option value="">Select Store Code</option>
                  {stores?.map(({ id, storeCode }: Store) => {
                    const formatedStoreCode =
                      storeCode.toString().length === 1 ? `0${storeCode}` : storeCode;
                    return (
                      <option key={id} value={formatedStoreCode}>
                        {formatedStoreCode}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              {/* FIlter time slot type */}
              <Form.Group className="search-input my-5" controlId="slotType">
                <Form.Select
                  className="input"
                  value={selectedSlotType}
                  onChange={(e: any) => setSelectedSlotType(e.target.value)}
                  data-testid="slotType"
                  name="slotType">
                  <option value="">Time slot type</option>
                  <option value={'normal'}>Normal</option>
                  <option value={'special'}>Special</option>
                  <option value={'express'}>Express</option>
                </Form.Select>
              </Form.Group>
              {/* FIlter active slot */}
              <Form.Group className="search-input my-5" controlId="filterActiveSlot">
                <Form.Select
                  className="input"
                  value={selectedActiveSlot}
                  onChange={(e: any) => setSelectedActiveSlot(e.target.value)}
                  data-testid="filterActiveSlot"
                  name="filterActiveSlot">
                  <option value="">Filter active slot</option>
                  <option value={'active'}>Active</option>
                  <option value={'in-active'}>InActive</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="my-5 search-input">
                <Button className="me-3" onClick={() => filterSlots()}>
                  Search
                </Button>
                <Button
                  data-testid="button_create"
                  variant="primary"
                  onClick={(e) => clearFilters()}>
                  Clear filters
                </Button>
              </Form.Group>
            </Row>
            {invalidFilter ? (
              <Container className="invalid-filter">{errorMessage}</Container>
            ) : (
              <Table className="time-slot-template-table" striped hover responsive>
                <thead>
                  <tr>
                    {TimeSlotTemplateColumns.map(({ header, field, className }) => (
                      <th key={field} className={className}>
                        {header}
                      </th>
                    ))}
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTemplates.map((slotTemplate, index) => (
                    <tr key={slotTemplate.id}>
                      {TimeSlotTemplateColumns.map(({ field, className, template }) => (
                        <td
                          data-testid={`${field}_${slotTemplate.id}`}
                          key={field}
                          className={className}>
                          {template
                            ? template(slotTemplate)
                            : String(slotTemplate[field as SlotTemplateFields])}
                        </td>
                      ))}
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            disabled={slotTemplate?.updating}
                            data-testid={`dropdown_${slotTemplate.id}`}>
                            {slotTemplate.updating ? '...' : 'Actions'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#"
                              onClick={(e) => onAction(e, 'update', slotTemplate)}
                              data-testid={`button_${slotTemplate.id}`}>
                              Update
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              data-testid={`active_button_${slotTemplate.id}`}
                              onClick={(e) => onAction(e, 'markAsActive', slotTemplate, index)}>
                              Mark As&nbsp;
                              {slotTemplate.isActive ? 'In-Active' : 'Active'}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Container>
      <CSVUploadModal
        show={showUploadModal}
        onHide={handleOnHide}
        onFileSelected={handleFileSelected}
        onChannelSelected={handleChannelSelected}
        onUpload={handleFileUpload}
        loading={loading}
        channels={channels}
      />
    </>
  );
}

export default withCookies(TimeSlotTemplate);
