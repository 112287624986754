import { useLazyQuery } from '@apollo/client';
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Pagination, Row, Spinner, Table } from 'react-bootstrap';
import { withCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  DB_TIME_FORMAT,
  FETCH_POLICY,
  FORBIDDEN_RESOURCE_ERROR,
  TIME_FORMAT
} from '../../constants';
import { TimeSlotColumns } from '../../constants/table-schema';
import { GET_SLOTS_BY_PAGINATION } from '../../graphql/queries/slot';
import { Slot, SlotFields, TimeSlotComponentProps, TimeSlotLocationState } from '../../types/slot';
import { errorEvent } from '../../utils/log-event';
import './index.scss';
import { Channel } from '../../types/slot-template';
import { Store } from '../../types/store';
import { GET_ALL_CHANNELS } from '../../graphql/queries/channel';
import { GET_ALL_STORES } from '../../graphql/queries/store';
import CustomTimePickerFormControl from '../../components/time-picker';
import moment from 'moment';

export function TimeSlot({ setUser, cookies }: TimeSlotComponentProps) {
  const accessToken = cookies.get('token');
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as TimeSlotLocationState;
  const [stores, setStores] = useState<Store[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [invalidFilter, setInvalidFilter] = useState(false);
  const [selectedChannelId, setSelectedChannelId] = useState('');
  const [selectedStoreCode, setSelectedStoreCode] = useState('');
  const [fromTimeValue, setFromTimeValue] = useState('0');
  const [toTimeValue, setToTimeValue] = useState('0');

  const [{ page, rowPerPage, isInit }, setPagination] = useState({
    page: locationState?.page || 1,
    rowPerPage: locationState?.rowPerPage || 10,
    isInit: true
  });
  const [{ slots, total }, setSlots] = useState<{
    total: number;
    slots: Slot[];
  }>({
    total: 0,
    slots: []
  });
  const [{ totalFiltered, filteredSlots }, setFilteredSlots] = useState<{
    totalFiltered: number;
    filteredSlots: Slot[];
  }>({
    totalFiltered: 0,
    filteredSlots: []
  });
  const [getSlots, { loading: isFetching, error }] = useLazyQuery<{
    slotsByPagination: {
      slots: Slot[];
      total: number;
      storeCode: string;
      channelId: string;
    };
  }>(GET_SLOTS_BY_PAGINATION, {
    fetchPolicy: FETCH_POLICY
  });
  const [getAllStores, { loading: isStoreFetching, error: storeError }] =
    useLazyQuery(GET_ALL_STORES);
  const [getAllChannels, { loading: isChannelsFetching, error: channelsError }] =
    useLazyQuery(GET_ALL_CHANNELS);

  useEffect(() => {
    const onInit = async () => {
      try {
        const variables: any = {
          accessToken,
          storeCode: selectedStoreCode,
          channelId: selectedChannelId,
          offset: page * rowPerPage - rowPerPage,
          limit: rowPerPage
        };
        if (fromTimeValue != '0') {
          variables.from = moment(fromTimeValue, TIME_FORMAT).format(DB_TIME_FORMAT);
        }

        if (toTimeValue != '0') {
          variables.to = moment(toTimeValue, TIME_FORMAT).format(DB_TIME_FORMAT);
        }
        const { data } = await getSlots({
          variables
        });
        const slots = data?.slotsByPagination?.slots || [];
        const total = data?.slotsByPagination?.total || 0;
        setSlots({ total, slots });
        setFilteredSlots({ filteredSlots: slots, totalFiltered: total });

        const { data: allStores } = await getAllStores({
          variables: {
            accessToken
          }
        });
        const sortedStoreCodes = allStores?.stores
          .map((store) => ({ ...store, storeCode: parseInt(store.storeCode) }))
          .sort((a, b) => a.storeCode - b.storeCode);
        setStores(sortedStoreCodes);
        const { data: allChannels } = await getAllChannels({
          variables: {
            accessToken
          }
        });
        setChannels(allChannels?.channels);
      } catch (error) {
        errorEvent(error);
      }
    };
    onInit();
  }, [getSlots, accessToken, page, rowPerPage, setSlots]);

  useEffect(() => {
    if (error?.message === FORBIDDEN_RESOURCE_ERROR) {
      setUser();
    } else if (error?.message) {
      toast.error(error?.message);
    }
  }, [error, setUser]);

  const onUpdate = (e: MouseEvent<HTMLElement>, slotTemplate?: Slot): void => {
    e.preventDefault();
    navigate(`/update-time-slot/${slotTemplate?.id}`, {
      state: {
        page,
        rowPerPage
      }
    });
  };
  const onSelectRowPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    const newRowPerPage = Number(event.target.value);
    const offset = page * newRowPerPage - newRowPerPage;
    const newTotalPage = Math.ceil((totalFiltered || 0) / newRowPerPage);
    if (offset < (totalFiltered || 0)) {
      setPagination({
        page,
        rowPerPage: newRowPerPage,
        isInit: false
      });
    } else {
      setPagination({
        page: newTotalPage,
        rowPerPage: newRowPerPage,
        isInit: false
      });
    }
  };
  const filterSlots = async () => {
    console.log(fromTimeValue);
    setInvalidFilter(false);
    const variables: any = {
      accessToken,
      storeCode: selectedStoreCode,
      channelId: selectedChannelId,
      offset: 0,
      limit: rowPerPage
    };
    if (fromTimeValue != '0') {
      variables.from = moment(fromTimeValue, TIME_FORMAT).format(DB_TIME_FORMAT);
    }

    if (toTimeValue != '0') {
      variables.to = moment(toTimeValue, TIME_FORMAT).format(DB_TIME_FORMAT);
    }
    const { data } = await getSlots({
      variables
    });
    const slots = data?.slotsByPagination?.slots || [];
    const total = data?.slotsByPagination?.total || 0;
    setSlots({ total, slots });
    setFilteredSlots({ filteredSlots: slots, totalFiltered: total });

    if (data && data?.slotsByPagination?.slots?.length > 0) {
      setInvalidFilter(false);
    } else {
      setInvalidFilter(true);
      getErrorMessage();
    }
  };

  const getChannelName = () => {
    if (selectedChannelId) {
      const channel = channels?.find(({ id }: Channel) => id === selectedChannelId);
      return channel?.channel_name || '';
    }
    return null;
  };

  const getErrorMessage = () => {
    const selectedChannelName = getChannelName();
    const error =
      selectedStoreCode && selectedChannelName
        ? `No slot found for storeCode  ${selectedStoreCode} and channel ${selectedChannelName}`
        : selectedStoreCode
        ? `No slot found for storeCode: ${selectedStoreCode}`
        : selectedChannelName
        ? `No slot found for channel: ${selectedChannelName}`
        : 'No slot found for the filter';
    setErrorMessage(error);
  };

  const clearFilters = async () => {
    setSelectedChannelId('');
    setSelectedStoreCode('');
    setFromTimeValue('');
    setToTimeValue('');
    setPagination({
      page: 1,
      rowPerPage,
      isInit: false
    });
    const { data } = await getSlots({
      variables: {
        accessToken,
        offset: page * rowPerPage - rowPerPage,
        limit: rowPerPage
      }
    });

    const slots = data?.slotsByPagination?.slots || [];
    const total = data?.slotsByPagination?.total || 0;
    setSlots({ total, slots });
    setFilteredSlots({ filteredSlots: slots, totalFiltered: total });
    setInvalidFilter(false);
    setFromTimeValue('0');
    setToTimeValue('0');
  };
  const totalPage = Math.ceil((totalFiltered || 0) / rowPerPage);
  if (isFetching && isInit) {
    return (
      <>
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="secondary" />
        <Spinner animation="grow" variant="success" />
      </>
    );
  }
  return (
    <Container className="mt-5">
      <Row className="mb-3">
        <Col>
          <h3>Existing Time-slot</h3>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="existing-time-slot-table">
          <Form.Group className="my-5">
            <Row>
              <Form.Group className="search-input" controlId="channel">
                <Form.Select
                  className="input"
                  value={selectedChannelId}
                  onChange={(e: any) => setSelectedChannelId(e.target.value)}
                  data-testid="channel"
                  name="channel">
                  <option value="">Select Channel</option>
                  {channels?.map(({ id, channel_name }: Channel) => (
                    <option key={id} value={id}>
                      {channel_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="search-input" controlId="channel">
                <Form.Select
                  className="input"
                  value={selectedStoreCode}
                  onChange={(e: any) => setSelectedStoreCode(e.target.value)}
                  data-testid="channel"
                  name="channel">
                  <option value="">Select Store Code</option>
                  {stores?.map(({ id, storeCode }: Store) => {
                    const formatedStoreCode =
                      storeCode.toString().length === 1 ? `0${storeCode}` : storeCode;
                    return (
                      <option key={id} value={formatedStoreCode}>
                        {formatedStoreCode}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              {/* Filter for from and to time value implemented on frontend */}

              <>
                <Form.Group className="d-flex align-items-center search-input">
                  <Form.Control
                    className="round-input login-input "
                    value={fromTimeValue}
                    onChange={(e) => {
                      setFromTimeValue(e.target.value);
                    }}
                    as={CustomTimePickerFormControl}
                    data-testid="from"
                    name="form"
                    placeholder="From"
                  />
                </Form.Group>
                <Form.Group className="search-input d-flex align-items-center">
                  <Form.Control
                    className="round-input login-input "
                    value={toTimeValue}
                    onChange={(e) => {
                      setToTimeValue(e.target.value);
                    }}
                    as={CustomTimePickerFormControl}
                    data-testid="to"
                    name="to"
                    placeholder="To"
                  />
                </Form.Group>
              </>

              {/* Ends */}
              <Form.Group className="search-input">
                <Button className="me-3" onClick={() => filterSlots()}>
                  Search
                </Button>
                <Button
                  data-testid="button_create"
                  variant="primary"
                  onClick={(e) => clearFilters()}>
                  Clear filters
                </Button>
              </Form.Group>
            </Row>
          </Form.Group>
          {invalidFilter ? (
            <Container className="invalid-filter">{errorMessage}</Container>
          ) : (
            <Table striped hover responsive>
              <thead>
                <tr>
                  {TimeSlotColumns.map(({ header, field, className }) => (
                    <th key={field} className={className}>
                      {header}
                    </th>
                  ))}
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {filteredSlots?.map((slot) => (
                  <tr key={slot.id}>
                    {TimeSlotColumns.map(({ field, className, template }) => (
                      <td data-testid={`${field}_${slot.id}`} key={field} className={className}>
                        {template ? template(slot) : String(slot[field as SlotFields])}
                      </td>
                    ))}
                    <td>
                      <Button data-testid={`button_${slot.id}`} onClick={(e) => onUpdate(e, slot)}>
                        Update
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {rowPerPage <= total && (
            <div className="d-flex align-items-center justify-content-end">
              <Form>
                <Pagination>
                  <fieldset>
                    <Form.Group className="pagination-select ">
                      <Form.Label htmlFor="recordPerPage">Record Per Page</Form.Label>
                      <Form.Select
                        data-testid="recordPerPage"
                        name="recordPerPage"
                        id="recordPerPage"
                        value={rowPerPage}
                        onChange={(e) => onSelectRowPerPage(e)}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                      </Form.Select>
                    </Form.Group>
                  </fieldset>
                  <Pagination.First
                    data-testid="first"
                    disabled={page === 1}
                    onClick={() =>
                      setPagination({
                        page: 1,
                        rowPerPage,
                        isInit: false
                      })
                    }
                  />
                  <Pagination.Prev
                    data-testid="previous"
                    disabled={page === 1}
                    onClick={() =>
                      setPagination({
                        page: page - 1,
                        rowPerPage,
                        isInit: false
                      })
                    }
                  />
                  <Pagination.Item data-testid="totalPage">
                    {page} of {totalPage}
                  </Pagination.Item>
                  <Pagination.Next
                    data-testid="next"
                    disabled={page === totalPage}
                    onClick={() =>
                      setPagination({
                        page: page + 1,
                        rowPerPage,
                        isInit: false
                      })
                    }
                  />
                  <Pagination.Last
                    data-testid="last"
                    disabled={page === totalPage}
                    onClick={() =>
                      setPagination({
                        page: totalPage,
                        rowPerPage,
                        isInit: false
                      })
                    }
                  />
                </Pagination>
              </Form>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default withCookies(TimeSlot);
