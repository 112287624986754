import { gql } from '@apollo/client';

// Define query
export const GET_ALL_CHANNELS = gql`
  query ($accessToken: String!) {
   channels(accessToken: $accessToken) {
       id
       channel_name
   }
}
`;
