import { withCookies } from 'react-cookie';
import { CutOffTimeComponentProps, StoreConfig } from '../../types/cut-off-time';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import './edit.scss';
import { FormData, formFields } from './edit-fields';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DB_TIME_FORMAT,
  FETCH_POLICY,
  FORBIDDEN_RESOURCE_ERROR,
  TIME_FORMAT
} from '../../constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_STORE_CONFIG } from '../../graphql/queries/slot-config';
import Placeholder from 'react-bootstrap/Placeholder';
import { UPDATE_STORE_CONFIG } from '../../graphql/mutations/store';
import LoadingButton from '../../components/loading-button';
import { toast } from 'react-toastify';
import { STORE_CONFIG_UPDATE_MSG } from '../../constants/messages';
import CustomTimePickerFormControl from '../../components/time-picker';
import moment from 'moment';

const InputField = ({ field, formData, onChange, isFetching, formErrors }: any) => {
  return (
    <>
      <Form.Group
        className="flex-1 d-flex align-items-center justify-content-start mb-3"
        key={field.name}
        controlId={field.name as string}>
        {field.type === 'checkbox' ? (
          <>
            <div className="me-3 w-30 label-container">
              <Form.Label>Enable Store Pickup</Form.Label>
            </div>
            {isFetching ? (
              <Placeholder xs={2} style={{ height: 40, backgroundColor: '#c1c1c1' }} />
            ) : (
              <Form.Check
                type="checkbox"
                name="isStorePickup"
                checked={formData.isStorePickup}
                onChange={onChange}
              />
            )}
          </>
        ) : (
          <>
            <div className="me-3 w-30 label-container">
              <Form.Label>{field.label}</Form.Label>
            </div>
            {isFetching ? (
              <Placeholder xs={8} style={{ height: 40, backgroundColor: '#c1c1c1' }} />
            ) : (
              <div className="input">
                {field.type === 'date' ? (
                  <Form.Control
                    type="text"
                    placeholder={field.placeholder}
                    name={field.name as string}
                    value={formData[field.name]}
                    onChange={onChange}
                    as={CustomTimePickerFormControl}
                    data-testid={`cutOffTime`}
                  />
                ) : (
                  <Form.Control
                    type="text"
                    placeholder={field.placeholder}
                    name={field.name as string}
                    value={formData[field.name]}
                    onChange={onChange}
                  />
                )}
                <div>
                  {formErrors[field.name] && (
                    <Form.Text className="text-danger">{formErrors[field.name]}</Form.Text>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </Form.Group>
    </>
  );
};

export function EditStoreConfig({ cookies, setUser }: CutOffTimeComponentProps) {
  const accessToken = cookies.get('token');
  const { storeCode } = useParams();
  const [getStoreConfig, { loading: isFetching, error }] = useLazyQuery<{
    storeConfig: StoreConfig;
  }>(GET_STORE_CONFIG, {
    fetchPolicy: FETCH_POLICY,
    variables: {
      accessToken,
      storeCode: storeCode === '804' ? '04' : storeCode
    }
  });
  const [updateStoreConfig, { loading: isUpdating, error: updateError }] =
    useMutation(UPDATE_STORE_CONFIG);
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [storeConfig, setStoreConfig] = useState<any>();

  const [formData, setFormData] = useState<FormData>({
    cutOffTime: '',
    nameEn: '',
    nameTh: '',
    address1: '',
    address2: '',
    address3: '',
    address1En: '',
    address2En: '',
    address3En: '',
    city: '',
    cityEn: '',
    state: '',
    stateEn: '',
    latitude: '',
    longitude: '',
    isStorePickup: null
  });
  const requiredFields = [
    'cutOffTime',
    'nameEn',
    'nameTh',
    'address1',
    'address1En',
    'latitude',
    'longitude',
    'cityEn'
  ];

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
    setFormErrors({
      ...formErrors,
      [name]: '' // Reset the error when the user starts typing
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Simple required validation
    const errors: Record<string, string> = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (
        ((typeof value === 'string' && value.trim() === '') || value === null) &&
        requiredFields.includes(key)
      ) {
        errors[key] = 'This field is required';
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    let updatedConfig: any = formData;
    updatedConfig.storeConfigurations = {
      ...storeConfig,
      pickupAtStoreEnable: formData.isStorePickup
    };
    formData.cutOffTime = moment(formData.cutOffTime, ['h:mm a']).format('HH:mm:ss');
    delete updatedConfig.isStorePickup;
    setFormData({
      ...formData,
      isStorePickup: updatedConfig.storeConfigurations.pickupAtStoreEnable ?? null
    });
    updatedConfig = {
      ...updatedConfig,
      storeConfigurations: JSON.stringify(updatedConfig.storeConfigurations)
    };
    const updatedStoreCode = storeCode === '804' ? '04' : storeCode;
    await updateStoreConfig({
      variables: {
        accessToken,
        storeCode: updatedStoreCode,
        updateStoreConfigInput: updatedConfig
      }
    });
    toast.success(STORE_CONFIG_UPDATE_MSG);
    onCancel();
  };

  useEffect(() => {
    if (updateError) {
      if (updateError?.message === FORBIDDEN_RESOURCE_ERROR) {
        setUser();
      } else {
        toast.error(updateError?.message);
      }
    }
  }, [updateError, setUser]);

  const fetchStoreConfig = async () => {
    const { data } = await getStoreConfig();
    if (data?.storeConfig) {
      const {
        cutOffHours,
        cutOffMinutes,
        cutOffSeconds,
        cutOffTimeZone,
        nameEn,
        nameTh,
        address1,
        address2,
        address3,
        address1En,
        address2En,
        address3En,
        city,
        cityEn,
        state,
        stateEn,
        latitude,
        longitude,
        storeConfigurations
      } = data?.storeConfig ?? {};
      const cutOffTime = moment(
        `${cutOffHours}:${cutOffMinutes}:${cutOffSeconds}+07`,
        DB_TIME_FORMAT
      ).format(TIME_FORMAT);
      const configurations = JSON.parse(storeConfigurations);
      setStoreConfig(configurations ?? {});
      const config = {
        cutOffTime,
        nameEn,
        nameTh,
        address1,
        address2,
        address3,
        address1En,
        address2En,
        address3En,
        city,
        cityEn,
        state,
        stateEn,
        latitude,
        longitude,
        isStorePickup: configurations?.pickupAtStoreEnable ?? false
      };
      setFormData(config);
    }
  };

  const onCancel = (): void => {
    navigate('/store-config');
  };

  useEffect(() => {
    fetchStoreConfig();
  }, [getStoreConfig]);

  return (
    <div className="update-time-slot-container">
      <Form onSubmit={handleSubmit}>
        <h3 className="ps-3 mb-3">ST{storeCode}: Update store configuration</h3>
        <div className="open">
          {formFields.map(
            (field) =>
              field.section === null && (
                <InputField
                  field={field}
                  formData={formData}
                  onChange={handleInputChange}
                  isFetching={isFetching}
                  formErrors={formErrors}
                />
              )
          )}
        </div>
        <div className="d-flex section-wrapper">
          <div className="th-section">
            <div className="version-wrapper">
              <h3>Thai version</h3>
            </div>
            {formFields.map(
              (field) =>
                field.section === 'th' && (
                  <InputField
                    field={field}
                    formData={formData}
                    onChange={handleInputChange}
                    isFetching={isFetching}
                    formErrors={formErrors}
                  />
                )
            )}
          </div>
          <div className="en-section">
            <div className="version-wrapper">
              <h3>English version</h3>
            </div>
            {formFields.map(
              (field) =>
                field.section === 'en' && (
                  <InputField
                    field={field}
                    formData={formData}
                    onChange={handleInputChange}
                    isFetching={isFetching}
                    formErrors={formErrors}
                  />
                )
            )}
          </div>
        </div>
        <div className="d-flex action-wrapper justify-content-end">
          <div className="action-container d-flex">
            <Button
              type="button"
              variant="outline-primary"
              onClick={onCancel}
              disabled={isUpdating}
              className="login-btn form-btn"
              data-testid="cancel">
              Cancel
            </Button>
            <LoadingButton
              label="Submit"
              className="ml-3"
              loadingLabel="Submitting..."
              isDisabled={isUpdating}
              isLoading={isUpdating}
              testId="submit"
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default withCookies(EditStoreConfig);
