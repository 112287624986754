import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/loading-button';
import { Slot, UpdateTimeSlotProps } from '../../types/slot';
import './index.scss';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_SLOT_BY_ID } from '../../graphql/mutations/slot';
import { withCookies } from 'react-cookie';
import {
  DATE_FORMAT,
  DB_TIME_FORMAT,
  FETCH_POLICY,
  FORBIDDEN_RESOURCE_ERROR,
  THAILAND_OFFSET_TIME,
  TIME_FORMAT
} from '../../constants';
import { TIME_SLOT_UPDATE_MSG, WARNING_FORM_NOT_UPDATE } from '../../constants/messages';
import { GET_SLOT_BY_ID } from '../../graphql/queries/slot';
import { errorEvent } from '../../utils/log-event';

export function UpdateTimeSlot({ cookies, setUser }: UpdateTimeSlotProps) {
  const accessToken = cookies.get('token');
  const navigate = useNavigate();
  const { state } = useLocation();
  const { slotId } = useParams();
  const [updateSlotById, { loading: isSaving, error: updateError }] =
    useMutation(UPDATE_SLOT_BY_ID);
  const {
    data,
    loading: isFetching,
    error
  } = useQuery<{
    slotById: Slot;
  }>(GET_SLOT_BY_ID, {
    fetchPolicy: FETCH_POLICY,
    variables: {
      accessToken,
      id: slotId
    }
  });
  useEffect(() => {
    const errorMsg = error?.message || updateError?.message;
    if (errorMsg === FORBIDDEN_RESOURCE_ERROR) {
      setUser();
    } else if (errorMsg) {
      toast.error(errorMsg);
    }
  }, [error, updateError, setUser]);

  const [formValues, setProfileFormValue] = useState<Partial<Slot>>({
    capacity: 0,
    oldCapacity: 0
  });

  useEffect(() => {
    if (data) {
      setProfileFormValue({
        capacity: data?.slotById.capacity,
        oldCapacity: data?.slotById.capacity
      });
    }
  }, [data]);

  const setFormValueByKeyValue = (key: string, value: string | boolean) => {
    setProfileFormValue({
      ...formValues,
      ...{
        [key]: value
      }
    });
  };

  const isValuesChange = (updateSlotInput: Partial<Slot>) => {
    if (updateSlotInput.capacity !== formValues?.oldCapacity) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    try {
      const updateSlotInput = {
        capacity: Number(formValues.capacity)
      };
      if (!isValuesChange(updateSlotInput)) {
        toast.warning(WARNING_FORM_NOT_UPDATE);
        return;
      }
      const { data } = await updateSlotById({
        variables: {
          accessToken,
          id: slotId,
          updateSlotInput
        }
      });
      if (data) {
        toast.success(TIME_SLOT_UPDATE_MSG);
        navigate('/time-slot', {
          state
        });
      }
    } catch (error) {
      errorEvent(error);
    }
  };

  const onCancel = (): void => {
    navigate('/time-slot', {
      state
    });
  };

  if (isFetching) {
    return (
      <>
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="secondary" />
        <Spinner animation="grow" variant="success" />
      </>
    );
  }
  return (
    <div className="update-time-slot-container">
      <h3 className="ps-3">Update Existing Time-slot</h3>
      <Form data-testid="update-time-slot-form" onSubmit={handleSubmit} className="mt-3">
        <Form.Group
          className="d-flex align-items-center justify-content-between mb-3"
          controlId="slotId">
          <div className="me-3 w-30 label-container">
            <Form.Label>Slot Id</Form.Label>
          </div>
          <div className="round-input input value" data-testid="slot-id">
            {data?.slotById?.id}
          </div>
        </Form.Group>
        <Form.Group
          className="d-flex align-items-center justify-content-between mb-3"
          controlId="channelName">
          <div className="me-3 w-30 label-container">
            <Form.Label>Channel</Form.Label>
          </div>
          <div className="round-input input value" data-testid="slot-id">
            {data?.slotById?.type?.channel?.channel_name || 'N/A'}
          </div>
        </Form.Group>
        <Form.Group
          className="d-flex align-items-center justify-content-between mb-3"
          controlId="storeCode">
          <div className="me-3 w-30 label-container">
            <Form.Label>Store Id</Form.Label>
          </div>
          <div className="round-input input value" data-testid="store-code">
            {data?.slotById?.type.storeCode}
          </div>
        </Form.Group>
        <Form.Group
          className="d-flex align-items-center justify-content-between mb-3"
          controlId="date">
          <div className="me-3 w-30 label-container">
            <Form.Label>Date</Form.Label>
          </div>
          <div className="round-input input value" data-testid="date">
            {data?.slotById?.date &&
              moment(data?.slotById?.date).utcOffset(THAILAND_OFFSET_TIME).format(DATE_FORMAT)}
          </div>
        </Form.Group>
        <Form.Group
          className="d-flex align-items-center justify-content-between mb-3"
          controlId="timeSlot">
          <div className="me-3 w-30 label-container">
            <Form.Label>Time Slot</Form.Label>
          </div>
        </Form.Group>
        <Form.Group
          className="d-flex align-items-center justify-content-between mb-3"
          controlId="timeSlotFrom">
          <div className="me-3 w-30 label-container">
            <Form.Label className="sub-label">From</Form.Label>
          </div>
          <div className="round-input input value" data-testid="from">
            {data?.slotById?.type?.from &&
              moment(data?.slotById?.type?.from, DB_TIME_FORMAT).format(TIME_FORMAT)}
          </div>
        </Form.Group>
        <Form.Group
          className="d-flex align-items-center justify-content-between mb-3"
          controlId="timeSlotTo">
          <div className="me-3 w-30 label-container">
            <Form.Label className="sub-label">To</Form.Label>
          </div>
          <div className="round-input input value" data-testid="to">
            {data?.slotById?.type?.from &&
              moment(data?.slotById?.type?.to, DB_TIME_FORMAT).format(TIME_FORMAT)}
          </div>
        </Form.Group>
        <Form.Group
          className="d-flex align-items-center justify-content-between mb-3"
          controlId="storeCode">
          <div className="me-3 w-30 label-container">
            <Form.Label>Reserved</Form.Label>
          </div>
          <div className="round-input input value" data-testid="store-code">
            {data?.slotById?.reserved}
          </div>
        </Form.Group>
        <Form.Group
          className="d-flex align-items-center justify-content-between mb-3"
          controlId="capacity">
          <div className="me-3 w-30 label-container">
            <Form.Label>Maximum Capacity</Form.Label>
          </div>
          <Form.Control
            className="round-input input"
            value={formValues.capacity}
            onChange={(e) => setFormValueByKeyValue('capacity', e.target.value)}
            min={1}
            type="number"
            placeholder="Max Capacity"
            required
            data-testid="capacity"
            name="capacity"
          />
        </Form.Group>
        <Form.Group
          className="d-flex align-items-center justify-content-start mb-3"
          controlId="isAvailable">
          <div className="me-3 w-30 label-container">
            <Form.Label>Is Active</Form.Label>
          </div>
          <Form.Check
            disabled={true}
            className="time-input"
            checked={data?.slotById?.type.isActive}
            type="checkbox"
            name="isActive"
            data-testid="is-active"
          />
        </Form.Group>
        <div className="mt-4 d-flex align-items-center justify-content-around">
          <div className="me-3 w-30 label-container">
            <Form.Label>&nbsp;</Form.Label>
          </div>
          <div className="d-flex align-items-center justify-content-evenly w-100">
            <LoadingButton
              label="Submit"
              loadingLabel="Submitting..."
              isDisabled={isSaving}
              isLoading={isSaving}
              testId={'submit'}
            />
            <Button
              type="button"
              variant="outline-primary"
              disabled={isSaving}
              onClick={onCancel}
              className="login-btn form-btn"
              data-testid="cancel">
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default withCookies(UpdateTimeSlot);
