import { gql } from '@apollo/client';

export const UPDATE_SLOT_BY_ID = gql`
  mutation ($accessToken: String!, $id: ID!, $updateSlotInput: UpdateSlotInput!) {
    slotUpdateById(accessToken: $accessToken, id: $id, UpdateSlotInput: $updateSlotInput) {
      id
      date
      capacity
      type {
        channel {
          id
        }
      }
    }
  }
`;
