import { User } from '../types/user';
import { Navigate } from 'react-router-dom';
const userStorageKey = 'userInfo';
export const getUserFromLocalStorage = (): User | null => {
  try {
    const userString = localStorage.getItem(userStorageKey);
    return userString ? (JSON.parse(userString) as User) : null;
  } catch (error) {
    return null;
  }
};

export const setUserToLocalStorage = (user: User): void => {
  localStorage.setItem(userStorageKey, JSON.stringify(user));
};

const navigateTo = (path: string) => {
  window.location.href = path;
};

export const removeUserToLocalStorage = (): void => {
  localStorage.removeItem(userStorageKey);
  navigateTo('login');
};
