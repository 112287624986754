import React from 'react';
import { Button } from 'react-bootstrap';

const DownloadCSVButton = () => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/slot-templates-bulk-upload.csv`;
    link.download = 'slot-templates-bulk-upload.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <a href="#" style={{ cursor: 'pointer' }} onClick={handleDownload}>
      Download CSV template
    </a>
  );
};

export default DownloadCSVButton;
