import { useMutation } from '@apollo/client';
import React, { FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { withCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/loading-button';
import { COMMON_API_FAIL_MSG, LOGIN_ERROR_MSG, LOGIN_SUCCESS_MSG } from '../../constants/messages';
import { LOGIN_USER } from '../../graphql/mutations/login';
import { LoginComponentProps, LoginForm } from '../../types/login';
import { errorEvent } from '../../utils/log-event';
import './index.scss';

export function Login({ cookies, setUser }: LoginComponentProps) {
  const [formValues, setProfileFormValue] = useState<LoginForm>({
    username: '',
    password: ''
  });
  const [loginUser, { data, loading, error }] = useMutation(LOGIN_USER);

  useEffect(() => {
    const loginSuccess = () => {
      const accessToken = data?.adminUserLogin?.accessToken || '';
      if (accessToken) {
        toast.success(LOGIN_SUCCESS_MSG);
        cookies.set('token', accessToken);
      } else {
        loginError(LOGIN_ERROR_MSG);
      }
    };

    const loginError = (errorMsg: string) => {
      toast.error(errorMsg);
    };
    if (!loading) {
      if (data) {
        loginSuccess();
      } else if (error) {
        loginError(error.message || COMMON_API_FAIL_MSG);
      }
    }
  }, [data, loading, error, cookies, setUser]);

  const setFormValueByKeyValue = (key: string, value: string) => {
    setProfileFormValue({
      ...formValues,
      ...{
        [key]: value
      }
    });
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const { username, password } = formValues;
    try {
      await loginUser({ variables: { username: username, password } });
      setUser({
        username: formValues.username
      });
    } catch (error) {
      errorEvent(error);
    }
  };

  return (
    <div className="login-container">
      <Form data-testid="login-form" onSubmit={handleLogin} className="mt-3">
        <Form.Group
          className="d-flex align-items-center justify-content-between mb-3"
          controlId="fromUsername"
        >
          <div className="mr-5 w-30">
            <Form.Label>Username</Form.Label>
          </div>
          <Form.Control
            className="round-input input"
            value={formValues.username}
            onChange={(e) => setFormValueByKeyValue('username', e.target.value)}
            type="text"
            placeholder="Username"
            required
            name="username"
            data-testid="username"
          />
        </Form.Group>
        <Form.Group
          className="d-flex align-items-center justify-content-between mb-3"
          controlId="fromPassword"
        >
          <div className="mr-5 w-30">
            <Form.Label>Password</Form.Label>
          </div>
          <Form.Control
            className="round-input input"
            value={formValues.password}
            onChange={(e) => setFormValueByKeyValue('password', e.target.value)}
            type="password"
            placeholder="Password"
            required
            name="password"
            data-testid="password"
          />
        </Form.Group>
        <div className="mt-4 d-flex align-items-center justify-content-between">
          <div className="mr-5 w-30">
            <Form.Label>&nbsp;</Form.Label>
          </div>
          <LoadingButton
            label="Login"
            loadingLabel="Logging..."
            isDisabled={loading}
            isLoading={loading}
            testId={'submit'}
            className="login-btn input"
          />
        </div>
      </Form>
    </div>
  );
}

export default withCookies(Login);
