import { gql } from '@apollo/client';

// Define mutation
export const LOGIN_USER = gql`
  mutation ($username: String!, $password: String!) {
    adminUserLogin(password: $password, username: $username) {
      accessToken
    }
  }
`;
