export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_API_URL ?? '';
export const GRAPHQL_URL_V2 = process.env.REACT_APP_GRAPHQL_API_URL_V2 ?? '';
export const DATE_FORMAT = 'DD MMMM YYYY';
export const TIME_FORMAT = 'h:mm a';
export const THAILAND_OFFSET_TIME = '+0700';
export const DB_TIME_FORMAT = 'HH:mm:ss+07';
export const FORBIDDEN_RESOURCE_ERROR = 'Forbidden resource';
export const FETCH_POLICY = 'network-only';
export const DEFAULT_TIME_SLOT_TEMPLATE = {
  from: '10:00 am',
  to: '11:00 am',
  maxCapacity: 1,
  isActive: true,
  storeCode: '',
  channelId: '',
  updateSlots: false,
  percentageForSM1: 0,
  isExpressSlot: false,
  isSpecialSlot: false
};
export const THAILAND_TIMEZONE = 'Asia/Bangkok';
export const API_COMMON_HEADER = {
  'x-app-version': '2.2.0',
  'x-device-platform': 'ios'
};
