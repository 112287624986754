export const LOGIN_SUCCESS_MSG = 'Login Successfully';
export const LOGIN_ERROR_MSG = 'Invalid login or password';
export const COMMON_API_FAIL_MSG = 'Error while calling web service';
export const TIME_SLOT_UPDATE_MSG = 'Time slot capacity update successfully';
export const UPDATE_CUTOFF_TIME_MSG = 'Cut-off time update successfully';
export const TIME_SLOT_TEMPLATE_CREATE_MSG =
  'Created slot template, refresh to load the created template.';
export const TIME_SLOT_TEMPLATE_UPDATE_MSG =
  'Updated slot template, refresh to load the updated template.';
export const STORE_CONFIG_UPDATE_MSG = 'Store config update successfully.';
export const TIME_RANGE_ERROR_MSG = 'To time should be grater then from time';
export const TIME_SLOT_TEMPLATE_STATUS_UPDATE_MSG = 'Time slot template {{STATUS}} successfully';
export const WARNING_FORM_NOT_UPDATE = 'Please update at least  one value';
