import { gql } from '@apollo/client';

// Define query
export const GET_SLOT_CONFIG = gql`
  query ($accessToken: String!) {
    slotConfigs(accessToken: $accessToken) {
      storeCode
      cutOffHours
      cutOffMinutes
      cutOffSeconds
      cutOffTimeZone
      nameTh
      nameEn
      latitude
      longitude
      address1
      address2
      isStorePickup
    }
  }
`;

export const GET_STORE_CONFIG = gql`
  query ($accessToken: String!, $storeCode: String!) {
    storeConfig(accessToken: $accessToken, storeCode: $storeCode) {
      id
      cutOffHours
      cutOffMinutes
      cutOffSeconds
      cutOffTimeZone
      nameTh
      nameEn
      legalNo
      class
      format
      contactPhone
      contactEmail
      contactFax
      address1
      address2
      address3
      address1En
      address2En
      address3En
      city
      state
      cityEn
      stateEn
      postCode
      countryCode
      latitude
      longitude
      status
      openedAt
      closedAt
      createdAt
      updatedAt
      storeConfigurations
    }
  }
`;
