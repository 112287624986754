import { MouseEvent } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { withCookies } from 'react-cookie';
import { Link, NavLink } from 'react-router-dom';
import { HeaderComponentProps } from '../../types/header';
import './index.scss';

export function Header({ user, setUser }: HeaderComponentProps) {
  const onLogout = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setUser();
  };
  const isActiveStyle = {
    fontWeight: 'bold'
  };

  return (
    <Navbar className="header-container" bg="light" expand="lg">
      <Container fluid>
        <Link className="navbar-brand" to="/time-slot">
          Fulfillment Admin Portal
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav className="me-auto my-2 my-lg-0 d-flex w-100 justify-content-end" navbarScroll>
            <NavLink
              style={({ isActive }) => (isActive ? isActiveStyle : {})}
              className="nav-link m-2"
              to="/time-slot">
              Existing Time-slot{' '}
            </NavLink>
            <NavLink
              style={({ isActive }) => (isActive ? isActiveStyle : {})}
              className="nav-link m-2"
              to="/time-slot-template">
              Time-slot Template{' '}
            </NavLink>
            <NavLink
              style={({ isActive }) => (isActive ? isActiveStyle : {})}
              className="nav-link m-2"
              to="/store-config">
              Store Config
            </NavLink>
            <Navbar.Text className="nav-link m-2 ml-0 mr-0 pointer">
              Signed in as:{' '}
              <a title="Logout" href="/" onClick={(e) => onLogout(e)}>
                {user?.username}
              </a>
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default withCookies(Header);
